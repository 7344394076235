
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































.alert {
  // prettier-ignore
  @include fluid(padding-right, (xxs: 20px, xxl: 40px));
  // prettier-ignore
  @include fluid(padding-left, (xxs: 20px, xxl: 40px));
  // prettier-ignore
  @include fluid(gap,(xxs: 10px, xxl: 20px));

  display: flex;
  border: 1px solid $elantis-blue;
  border-radius: 8px;

  &.warning {
    border-color: $elantis-orange;
  }
}

.alert__icon {
  flex-shrink: 0;
  margin-top: 5px;
  fill: $elantis-blue;

  .warning & {
    fill: $elantis-orange;
  }

  .big & {
    width: 4.8rem;
    height: 4.8rem;
  }
}

.alert__title {
  display: flex;
  align-items: center;

  .warning & {
    color: $elantis-orange;
  }

  .big & {
    font-size: 3.6rem;
  }
}

.alert__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}
