
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















.checkbox {
  position: relative;
  display: flex;
  align-items: center;
}

input {
  @extend %visually-hidden;

  border: 0;

  &:checked + label::after {
    display: block;
  }

  &:focus-visible + label {
    outline-color: $denim;
  }
}

.label {
  padding: 0 0 0 2.5rem;
  color: $c-black;
  font-size: 1.8rem;
  line-height: 1;
  border-radius: 0.2rem;
  outline-offset: 0.5rem;
  outline: 2px transparent solid;

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    width: 2rem;
    height: 2rem;
    border: 0.1rem solid $tundora;
    border-radius: 0.4rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0.2rem;
    left: 0.4rem;
    display: none;
    width: 1.2rem;
    height: 1.2rem;
    background: $c-turquoise-medium;
    border-radius: 0.2rem;
  }
}
