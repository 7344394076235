
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

































































































































































































































































































































.profile-outer {
  position: relative;
}

.profile__h2 {
  color: $c-gray-darker;
}

.address {
  @include mq(l) {
    display: flex;
    gap: 10rem;

    .fields__field {
      width: 50%;
    }
  }
}

.profile__zip {
  position: relative;
  flex: 1;
}

.profile__zip__options {
  position: absolute;
  z-index: 2;
  top: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  gap: 1rem;
  background-color: $c-white;
  border-radius: 1rem;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 10%);
}

.profile__zip__options__item {
  margin: 0 1rem;
  padding: 0 1rem;
  text-transform: uppercase;
  list-style-type: none;
  cursor: pointer;

  &:hover {
    background: #e7e7e7;
    border-radius: 0.8rem;
  }
}

.fields {
  width: 100%;
  margin-top: 0;
  padding: 0;

  @include mq(l) {
    width: auto;

    .address & {
      width: 50%;
    }
  }
}

.fields__input {
  @extend %focusable;

  flex: 1;
  padding: 0;

  &.is-disabled {
    border-bottom: 2px solid $c-white;
  }

  ::v-deep input {
    padding: 0;
  }
}

.fields__field {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 10px, xxl: 20px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 10px, xxl: 15px));

  align-items: center;
  list-style-type: none;
  border-bottom: 1px solid $elantis-grey-light;

  @include mq(xl) {
    display: flex;
  }
}

.fields__field__label {
  width: col(7, 14);
  white-space: nowrap;

  @include mq(xs, s) {
    width: col(5, 14);
  }

  @include mq(l) {
    width: col(3, 9);

    .single &,
    .address & {
      width: auto;
      margin-right: 2rem;
    }
  }
}

.profile__links {
  display: flex;
  gap: 4rem;
}

.profile__submit {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding: 1rem 0;
  background-color: $c-white;
}
